import * as React from 'react';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useLocation, useNavigate } from "react-router-dom";
import img from "../res/Alemao.png"
// import video from "../res/mauro.mp4"
const id = "18333"
const name = "Alemão"

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function content() {
    return <>
        <div style={{ justifyContent: 'center', display: 'flex', width: '100%' }} width="100%">
            <img src={img} style={{ width: '40%', }} />
        </div>
        <Typography>
            <h1>Projetos e Propostas</h1>
        </Typography>
        <Typography gutterBottom>
            <b>A Saúde Não Espera</b><br />
            A espera na saúde gera sofrimento. Defendemos políticas públicas para acabar com a espera/sofrimento por exames, consultas, cirurgias, reabilitação e etc.
        </Typography>
        <br />
        <Typography gutterBottom>
            <b>Educação Vale +</b><br />
            Valorização dos professores através de programas de capacitação, melhoria no plano de carreira e aumento na remuneração.
        </Typography>
        <br />
        <Typography gutterBottom>
            <b>Lixo Zero</b><br />
            Viabilização da reciclagem como atividade econômica para aumentar o reaproveitamento de resíduos e política de diminuição do impacto ambiental.
        </Typography>
        <br />
        <Typography>
            <b>Emprego & Renda</b><br />
            Programa para incentivar a aquisição de produtos e serviços locais, gerando empregos e fortalecendo nossa economia local.
        </Typography>
        <br />
        <Typography>
            <b>ePrefeitura</b><br />
            Implantação de um sistema integrado de gestão pública que permitirá aos cidadãos gerenciar seus processos administrativos junto à prefeitura através de canais virtuais de atendimento.
        </Typography>
    </>
}

export default function AlemaoDialog() {
    var loc = useLocation().pathname
    var len = loc.length
    const [open, setOpen] = React.useState(
        loc.startsWith('/candidatos/')
        &&
        loc.substring(len - 5, len) == id)

    const handleClickOpen = () => {
        setOpen(true);
        window.history.pushState({}, undefined, '/candidatos/' + id);
    };

    var nav = useNavigate()
    const handleClose = () => {
        window.history.pushState({}, undefined, '/candidatos');
        setOpen(false);
    };
    return (
        <React.Fragment>
            <Button variant="outlined" onClick={handleClickOpen} style={{ padding: 0 }}>
                <Item><Typography >{name}</Typography>
                    { <img src={img} style={{ width: '85%' }} /> }
                    <Typography >{id}</Typography>
                </Item>
            </Button>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open} >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {name} - {id}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    {content()}
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}