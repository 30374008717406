import logo from './res/logo-rede-principal-cor-300dpi.png';
import './App.css';
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import {
  Routes,
  Route,
  Outlet,
  Link,
  useLocation,
  useNavigate,
  useParams,
  Router,
} from "react-router-dom";

import PrinciplesDialog from './PrinciplesDialog';
import CandidatesDialog from './CandidatesDialog';
import ProposalDialog from './ProposalDialog';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


const App = () => {

  return (
    <div className="App">
    <Routes>
        <Route path="/principios" />
        <Route path="/propostas" />
        <Route path="/candidatos"> 
          <Route path=":id"/>
        </Route>
      </Routes>
      
      <header className="App-header" style={{ padding: '0px' }}>
        <Stack spacing={2} style={{ padding: '10px' }}>
          <Item><img src={logo} style={{ width: '100%', maxWidth: '720px' }} /></Item>
          <Item><PrinciplesDialog /></Item>
          <Item><ProposalDialog /></Item>
          <Item><CandidatesDialog /></Item>
        </Stack>
      </header>
    </div>
  );
}

export default App;
