    import * as React from 'react';
    import Dialog from '@mui/material/Dialog';
    import AppBar from '@mui/material/AppBar';
    import Toolbar from '@mui/material/Toolbar';
    import IconButton from '@mui/material/IconButton';
    import Typography from '@mui/material/Typography';
    import CloseIcon from '@mui/icons-material/Close';
    import Slide from '@mui/material/Slide';
    import Grid from '@mui/material/Unstable_Grid2';
    import MauroDialog from './candidates/MauroMoreira';
    import { Button } from '@mui/material';
    import { useLocation, useNavigate, useNavigation } from 'react-router-dom';
    import cdds from "./res/candidates.json"
    import AngelaDialog from './candidates/AngelaDialog';
import AlexDialog from './candidates/AlexDialog';
import AlemaoDialog from './candidates/AlemaoDialog';
import BabiDialog from './candidates/BabiDialog';
import JoaoDialog from './candidates/JoaoDialog';
import LeandroDialog from './candidates/LeandroDialog';
import OrivaldoDialog from './candidates/OrivaldoDialog';
import ValDialog from './candidates/ValDialog';
import VanessaDialog from './candidates/VanessaDialog';

    const styleButBlue = { width: '100%', backgroundColor: '#28B7C3', color: "#FFFFFF", borderColor: "#319F8D" }

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    export default function CandidatesDialog({ params }) {
        const [open, setOpen] = React.useState(useLocation().pathname.startsWith('/candidatos'));
        var nav = useNavigate()
        const handleClose = () => {
            window.history.pushState({}, undefined, '/');
            setOpen(false);
        };
        const handleClickOpen = () => {
            window.history.pushState({}, undefined, '/candidatos');
            setOpen(true);
        };

        return (
            <React.Fragment>
                <Button variant="outlined" style={styleButBlue} onClick={handleClickOpen}>
                    Candidatos 2024
                </Button>
                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                NOSSOS CANDIDATOS DE 2024
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Grid style={{ padding: '10px' }} container spacing={{ xs: 1 }} columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                        <Grid xs={6} sm={4} md={3} lg={2} xl={1} key={"18222"}>
                            <MauroDialog/>
                        </Grid>
                        <Grid xs={6} sm={4} md={3} lg={2} xl={1} key={"18144"}>
                            <AlexDialog/>
                        </Grid>
                        <Grid xs={6} sm={4} md={3} lg={2} xl={1} key={"18333"}>
                            <AlemaoDialog/>
                        </Grid>
                        <Grid xs={6} sm={4} md={3} lg={2} xl={1} key={"18000"}>
                            <AngelaDialog/>
                        </Grid>
                        <Grid xs={6} sm={4} md={3} lg={2} xl={1} key={"18888"}>
                            <BabiDialog/>
                        </Grid>
                        <Grid xs={6} sm={4} md={3} lg={2} xl={1} key={"18001"}>
                            <JoaoDialog/>
                        </Grid>
                        <Grid xs={6} sm={4} md={3} lg={2} xl={1} key={"18006"}>
                            <LeandroDialog/>
                        </Grid>
                        <Grid xs={6} sm={4} md={3} lg={2} xl={1} key={"18123"}>
                            <OrivaldoDialog/>
                        </Grid>

                        <Grid xs={6} sm={4} md={3} lg={2} xl={1} key={"18777"}>
                            <ValDialog/>
                        </Grid>
                        <Grid xs={6} sm={4} md={3} lg={2} xl={1} key={"18999"}>
                            <VanessaDialog/>
                        </Grid>
                    </Grid>
                    
                </Dialog>
            </React.Fragment>
        );
    }