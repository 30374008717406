import * as React from 'react';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useLocation, useNavigate } from "react-router-dom";

import img from "../res/joão Rossi.png"
// import video from "../res/mauro.mp4"
const id = "18001"
const name = "João Rossi"

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function content(){
    return <>
    <div style={{justifyContent: 'center', display: 'flex', width: '100%'}} width="100%">
                    <img src={img} style={{ width: '40%', }} />
                    </div>
                    <Typography>
                        <h1>Acessibilidade e Inclusão: Um Compromisso para Bragança Paulista</h1>
                    </Typography>
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/kEApNgoMG3w?si=88b3XanD0pGG8yzz" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    <br /> Meu nome é <b>João Paulo de Alcântara Rossi</b> sou cidadão de Bragança Paulista e convivo com a
Paralisia Cerebral desde o meu nascimento, causada pela falta de oxigênio no momento do parto.
Desde então, enfrento desafios diários, como dificuldades de locomoção, mas sempre me mantive
firme na busca por uma vida acessível e inclusiva.<br />
<br />Aos dois anos de idade, consegui dar meus primeiros passos, e desde então, tenho passado por
tratamentos e cirurgias que visam melhorar a minha qualidade de vida, como alongamento de
tendão para correção da minha marcha. No entanto, um dos maiores desafios que enfrento não é
apenas a condição física, mas a falta de acessibilidade nas ruas da nossa cidade. O estado precário
das calçadas e a ausência de infraestrutura adequada já me fizeram cair inúmeras vezes, dificultando
meu direito fundamental de ir e vir.<br />
<br />É com essa vivência e experiência que venho propor um compromisso com todos os cidadãos de
Bragança Paulista, em especial aqueles que, assim como eu, enfrentam desafios de mobilidade e
acessibilidade. Minhas propostas visam criar uma cidade mais inclusiva, para promover sua
participação na vida econômica, social e cultural, onde todos possam exercer plenamente seus
direitos.<br />
<br />Eu conheço de perto os desafios de quem vive com deficiência, e é por isso que estou
comprometido em lutar por uma Bragança Paulista mais acessível, justa e inclusiva para todos.
Conto com o seu apoio para que possamos, juntos, construir essa cidade que respeite e valorize
todos os seus cidadãos, sem exceção.
                    <Typography>
                        <h1>Projetos</h1>
                    </Typography>
                    <Typography gutterBottom>
                        <b>Melhoria das Calçadas</b><br />
                        Precisamos transformar as calçadas de nossa cidade em espaços
                        acessíveis, garantindo a mobilidade segura de todos, especialmente daqueles com deficiência
                        ou mobilidade reduzida. O direito de ir e vir precisa ser respeitado e assegurado.                    </Typography>
                    <br />
                    <Typography gutterBottom>
                        <b>Transporte Público Acessível</b><br />
                        É urgente termos ônibus acessíveis, com rampas e sem
                        degraus, permitindo que todos, sem exceção, possam utilizar o transporte público de forma
                        segura e digna.
                    </Typography>
                    <br />
                    <Typography gutterBottom>
                        <b>Acesso a Tratamento pelo SUS</b><br />
                        Lutarei para garantir que o Sistema Único de Saúde
                        (SUS) ofereça os tratamentos necessários para a reabilitação e melhoria da qualidade de vida
                        das pessoas com deficiência, assegurando acesso rápido e eficiente.                    </Typography>
                    <br />
                    <Typography>
                        <b>Aplicação do Estatuto da Pessoa com Deficiência</b><br />
                        É preciso garantir que os direitos
                        previstos no Estatuto da Pessoa com Deficiência sejam efetivamente aplicados em nossa
                        cidade, assegurando uma vida digna e plena para todas as pessoas com deficiência.                    </Typography>
                        <br />Agradeço a sua atenção e conto com seu voto para fazer essa mudança acontecer.
                        Com esperança e determinação,<br />
                        <b>João Paulo de Alcântara Rossi Número 18001.</b>
    </>
}
export default function JoaoDialog({ data }) {
    var loc = useLocation().pathname
    var len = loc.length
    const [open, setOpen] = React.useState(
        loc.startsWith('/candidatos/')
        &&
        loc.substring(len - 5, len) == id)

    const handleClickOpen = () => {
        setOpen(true);
        window.history.pushState({}, undefined, '/candidatos/' + id);
    };

    var nav = useNavigate()
    const handleClose = () => {
        window.history.pushState({}, undefined, '/candidatos');
        setOpen(false);
    };
    return (
        <React.Fragment>
            <Button variant="outlined" onClick={handleClickOpen} style={{ padding: 0 }}>
                <Item><Typography >{name}</Typography>
                    <img src={img} style={{ width: '85%' }} />
                    <Typography >{id}</Typography>
                </Item>
            </Button>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open} >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {name} - {id}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    {content()}
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}