import * as React from 'react';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useLocation, useNavigate } from "react-router-dom";

import img from "../res/Barbara.png"
// import video from "../res/mauro.mp4"
const id = "18888"
const name = "Babi Rossi"

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function content(){
    return <>
    <div style={{justifyContent: 'center', display: 'flex', width: '100%'}} width="100%">
                   
                    <img src={img} style={{ width: '40%', }} />
                    </div>
                    <Typography>
                        <h1>Barbara rossi 18888</h1>
                    </Typography>
                    <br />  Sou surda e, ao longo da vida, enfrentei diversas dificuldades na escola, no trabalho e na
sociedade. Mas nunca deixei de sonhar e de lutar por um futuro melhor para todos. Não deixei
que essas barreiras me impedissem de sonhar. Pelo contrário, cada desafio fortaleceu meu desejo
de lutar por um mundo melhor e mais acessível. Acredito em uma Bragança Paulista mais
sustentável, diversa, representativa, justa, inclusiva e democrática. E convido vocês a se unirem a
mim para fazermos isso acontecer! Juntos, somos mais fortes. Sou Babi Rossi, candidata a
vereadora com o número 18888<br />

<br /><iframe width="560" height="315" src="https://www.youtube.com/embed/zblebKJ3CMw?si=gLixO33C4W2vfTzU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    
                    <Typography>
                        <h1>De onde vem as libras?</h1>
                    </Typography>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/nBV542EO6fE?si=GseN_gPk_phraT5I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    <Typography >
                    <Typography>
                        <h1>Projetos</h1>
                    </Typography>
                        <b>Equipar melhor as escolas e ampliar a oferta de creches e educação em período integral</b><br />
                    </Typography>
                    <br />
                    <Typography >
                        <b>Valorizar os professores, melhorando seus salários e condições de trabalho e facilitando o
acesso a novas tecnologias.</b><br />
                    </Typography>
                    <br />
                    <Typography g>
                        <b>Apoiar a inclusão de pessoas com deficiência auditiva ou surdez na escola e no trabalho</b><br />
                    </Typography>
                    <br />
                    <Typography>
                        <b>Disponibilizar atendimento em Libras (Língua Brasileira de Sinais) através do aplicativo
                        ICOM.</b><br />
                    </Typography>
                    <br />
                    <Typography>
                        <b>Promover esporte e cultura acessíveis.</b><br />
                    </Typography>
                    <br />
                    <Typography>
                        <b>Cuidar das praças públicas e áreas de convivência.</b><br />
                    </Typography>
                    <Typography>
                        <b>Promover esporte e cultura acessíveis.</b><br />
                    </Typography>
                    <br />
                    <Typography>
                        <b>Proteger a primeira infância e dar assistência aos idosos.</b><br />
                    </Typography>
                    <br />
                    <Typography>
                        <b>Buscar soluções para gerar mais emprego e renda</b><br />
                    </Typography>
                    <br />
                    <br /> Se quiserem mais informações ou participar
ativamente desta construção, entrem em contato. Vamos juntos tomar as rédeas do nosso
presente, porque o futuro depende do que fizermos agora! <br />
    </>
}

export default function BabiDialog({ data }) {
    var loc = useLocation().pathname
    var len = loc.length
    const [open, setOpen] = React.useState(
        loc.startsWith('/candidatos/')
        &&
        loc.substring(len - 5, len) == id)

    const handleClickOpen = () => {
        setOpen(true);
        window.history.pushState({}, undefined, '/candidatos/' + id);
    };

    var nav = useNavigate()
    const handleClose = () => {
        window.history.pushState({}, undefined, '/candidatos');
        setOpen(false);
    };
    return (
        <React.Fragment>
            <Button variant="outlined" onClick={handleClickOpen} style={{ padding: 0 }}>
                <Item><Typography >{name}</Typography>
                    <img src={img} style={{ width: '85%' }} />
                    <Typography >{id}</Typography>
                </Item>
            </Button>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open} >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {name} - {id}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    {content()}
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}