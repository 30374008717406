import * as React from 'react';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useLocation, useNavigate } from "react-router-dom";

import img from "../res/Angela.png"
// import video from "../res/mauro.mp4"
const id = "18000"
const name = "Ângela Rossi"

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function content(){
    return <>
    <div style={{justifyContent: 'center', display: 'flex', width: '100%'}} width="100%">
                    <img src={img} style={{ width: '40%', }} />
                    </div>
                    <Typography>
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/plydgPNMynk?si=Be5hyucrSfcuaF0n" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        <h1>Um Momento da Sua Atenção</h1>
                        <br />Sou Ângela Lattanzi Rossi, Diretora Fundadora da Associação Beneficente São Lucas, onde
atendemos mais de 200 famílias, acumulando anos de experiência na área social. Agora, como
candidata a vereadora, trago essa bagagem e peço seu apoio com o voto no número 18000. Estou aqui
para oficializar minha candidatura e para que vocês conheçam nossa missão: somos pessoas que ainda
sonham, mas, mais do que isso, queremos transformar esses sonhos em realidade. Acreditamos que
podemos melhorar a nossa cidade, preservar o meio-ambiente e mudar o futuro. Se dá para imaginar,
dá para fazer acontecer
                        <br />Um Momento da Sua Atenção
Eu peço sua atenção, porque somos nós os responsáveis pela mudança que tanto buscamos.
Enfrentamos problemas que dificultam a vida de todos nós: falta de saúde, educação de qualidade,
empregos, acessibilidade e a degradação do meio ambiente. Conhecemos esses problemas de perto e
estamos cansados de promessas vazias. Chegou a hora de uma conversa séria!
                        <br />Queremos equipar nossas escolas, aumentar a oferta de creches e educação em período integral,
melhorar os salários e as condições de trabalho dos professores, cuidar das praças públicas, aprimorar
o atendimento no SUS, proteger a primeira infância e dar assistência aos nossos idosos. Também
buscamos soluções para aumentar o emprego e a renda na cidade.
                    <b>Junte-se a Nós!</b><br />
                    Esta é apenas a primeira etapa de nossa jornada. O tempo, os recursos e nosso espaço são limitados,
                    mas nossas propostas são do tamanho das necessidades de Bragança Paulista. Não consigo detalhar
                    todas elas aqui, mas convido você a acessar nossas redes sociais e conhecer mais. Nós desenvolvemos
                    projetos independentes de campanha porque eleições passam mas ações ficam. Caso queira discutir
                    ideias, ou se engajar ativamente, entre em contato! Vamos juntos tomar as rédeas do presente para
                    garantir um futuro melhor para todos.
                    <br />O espaço é a urna e o tempo é agora, em 2024. Daqui a quatro anos, que possamos olhar para trás e
                    dizer com orgulho: "Fizemos o nosso melhor por uma cidade que é de todos e para todos."
                    </Typography>
                    <Typography>
                        <h1>Projetos</h1>
                    </Typography>
                    <Typography gutterBottom>
                        <b>Educação Inclusiva</b><br />
                        Suporte e adaptações para garantir o aprendizado de todos os alunos.
                    </Typography>
                    <br />
                    <Typography gutterBottom>
                        <b>Acessibilidade</b><br />
                        Construir uma cidade acessível para todos os cidadãos.
                    </Typography>
                    <br />
                    <Typography gutterBottom>
                        <b>Emprego e Oportunidade</b><br />
                        Valorizar todos as contribuições nos ambientes de trabalho.
                    </Typography>
                    <br />
                    <Typography>
                        <b>Legislação e Políticas inclusivas</b><br />
                        Implementar leis que protejam os direitos das pessoas com deficiência.
                    </Typography>
                    <br />
                    <Typography>
                        <b>Concientização e Sensibilidade</b><br />
                        Sensibilizar a população sobre os direitos das pessoas com deficiência
                    </Typography>
                    <br />
                    <b>Participação Comunitária</b><br />
                    Ouvir a comunidade para atender melhor às suas necessidades.
                        <br />
                    <Typography>
                    <br />
                        <b>Espaços Verdes e Lazer</b><br />
                        Criar áreas de lazer acessíveis e sustentáveis.
                    </Typography>
                    
    </>
}

export default function AngelaDialog({ data }) {
    var loc = useLocation().pathname
    var len = loc.length
    const [open, setOpen] = React.useState(
        loc.startsWith('/candidatos/')
        &&
        loc.substring(len - 5, len) == id)

    const handleClickOpen = () => {
        setOpen(true);
        window.history.pushState({}, undefined, '/candidatos/' + id);
    };

    var nav = useNavigate()
    const handleClose = () => {
        window.history.pushState({}, undefined, '/candidatos');
        setOpen(false);
    };
    return (
        <React.Fragment>
            <Button variant="outlined" onClick={handleClickOpen} style={{ padding: 0 }}>
                <Item><Typography >{name}</Typography>
                    <img src={img} style={{ width: '85%' }} />
                    <Typography >{id}</Typography>
                </Item>
            </Button>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open} >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {name} - {id}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    {content()}
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}