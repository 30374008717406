import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Button } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const styleButBlue = { width: '100%', backgroundColor: '#28B7C3', color: "#FFFFFF", borderColor: "#319F8D" }

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProposalDialog({ }) {
    const [open, setOpen] = React.useState(useLocation().pathname == '/propostas');

    const handleClickOpen = () => {
        setOpen(true);
        window.history.pushState({}, undefined, '/propostas')

    };

    var nav = useNavigate()
    const handleClose = () => {
        window.history.pushState({}, undefined, '/');
        setOpen(false);
    };

    return (
        <React.Fragment>
            {/* <Link to='/proposal'> */}
                <Button variant="outlined" style={styleButBlue} onClick={handleClickOpen}>
                    Propostas
                </Button>
            {/* </Link> */}
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            NOSSAS PROPOSTAS
                        </Typography>
                    </Toolbar>
                </AppBar>
                <List>
                    <ListItemButton>
                        <ListItemText primary="Educação"
                            secondary="A educação básica de Bragança precisa de socorro para garantir
o futuro de nossos filhos e netos. Nossos Professores são as pessoas mais importantes da
sociedade, vamos apoiá-los e valorizá-los com aumento salarial, melhorias no plano de
carreira e capacitação. Apenas 55% de nossas crianças têm vagas garantidas nas creches.
Lutaremos para que todas crianças de Bragança possuam vagas nas creches. A educação
infantil é muito importante para o desenvolvimento de nossas crianças, permitindo que os
pais trabalhem e gerem renda." />
                    </ListItemButton>
                    <Divider />
                    <ListItemButton>
                        <ListItemText
                            primary="Saúde"
                            secondary="É possível diminuir a espera por exames, a espera consultas
com especialistas, a espera por cirurgias, a espera nas UPAS e Pronto Socorro. Propomos a
reserva de leitos hospitalares especialmente para nossos idosos. A espera na saúde gera
sofrimento. Venha comigo ajudar a melhorar a saúde no SUS."
                        />
                    </ListItemButton>
                    <Divider />
                    <ListItemButton>
                        <ListItemText
                            primary="Meio Ambiente"
                            secondary="Os Objetivos de Desenvolvimento Sustentável são um apelo global à ação para acabar com a pobreza, proteger o meio ambiente e o clima e garantir que as pessoas, em todos os lugares, possam desfrutar de paz e de prosperidade.A sustentabilidade é mais do que um conceito; é a essência que guia a missão da REDE. Compreender a profundidade desse termo é essencial para alcançar a visão que almejamos e a prática que nos move."
                        />
                    </ListItemButton>
                    <Divider />
                    <ListItemButton>
                        <ListItemText
                            primary="Emprego & Renda Local"
                            secondary="Incentivar a compra de produtos e serviços em
fornecedores e prestadores do município, fortalecendo a economia local e gerando emprego
em Bragança. Teremos como prioridade estabelecer unidades de capacitação profissional que
preparem nossos jovens para o mercado de trabalho."
                        />
                    </ListItemButton>
                </List>
            </Dialog>
        </React.Fragment>
    );
}