import { InstagramEmbed } from 'react-social-media-embed';
import * as React from 'react';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useLocation, useNavigate } from "react-router-dom";
import img from "../res/Leandro.png"
const id = "18006"
const name = "Prof. Leandro"

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function content() {
    return <>
        <div style={{ justifyContent: 'center', display: 'flex', width: '100%' }} width="100%">
        </div>
        <InstagramEmbed
            url='https://www.instagram.com/leandro_ce_alves/'
            clientAccessToken='123|456'
            width='100%'
            style={{width:'100%'}}
            hideCaption={false}
            containerTagName='div'
            protocol=''
            injectScript
            onLoading={() => { }}
            onSuccess={() => { }}
            onAfterRender={() => { }}
            onFailure={() => { }}
        />

        <Typography>
            <h1>Educação e Ética: Um Compromisso com Bragança</h1>
        </Typography>
        Sou graduado em Pedagogia, História, Filosofia e Educação Física, Mestrando em Educação e pós-graduado em Gestão Pública pela Federal de Alfenas. Também possuo especializações em Gestão Escolar e Gestão de Pessoas pela USP, além de Gestão Educacional e Liderança pela USF e uma Pós-graduação em Ética e Filosofia Política.  <br />
        <br />Escolhi o partido REDE SUSTENTABILIDADE por ser uma nova opção em nossa cidade, liderado pelo médico ortopedista Mauro Moreira e pela advogada e ativista social Vanessa Benchik, ambos comprometidos com o bem comum e com a ética. Um partido em Bragança sem vínculos com grupos políticos de ações duvidosas. Na Câmara, votarei sempre pelo que é certo e benéfico para o povo, jamais atendendo a interesses de grupos políticos.  <br />
        <br />Minha decisão de me candidatar vem de um profundo sentimento de insatisfação e inconformismo com o cenário político atual, especialmente no que tange à Educação Pública e aos jovens. Estou cansado de solicitar ajuda aos vereadores e ao poder público para questões essenciais e, até hoje, permanecer na lista de espera.<br />
        <Typography>
            <h1>Propostas: </h1>
        </Typography>
        1.Doação do salário de Vereador para projetos sociais.<br />
         <br /> 2.	Criação do bolsa-auxílio no valor de R$ 640,00 para jovens do Tiro de Guerra com estágio temporário nas secretarias da prefeitura.
        <br /> 
        <br />3.	Criação do “Bragantinas Unidas” para mulheres terem uma rede de apoio visando cuidados, acolhimento e empreendedorismo.<br />
        <br />4.	Concurso Público de Psicólogos e Psicopedagogos para todas as escolas de Bragança Paulista e para as Praças Educativas <br />
<br />5.	Programa Primeiro Emprego para jovens do último ano do Ensino Médio e criação do Projeto Jovem do Futuro, oferecendo apoio psicológico, social, formação profissional e estágio remunerado para jovens de 14 a 16 anos em situação de vulnerabilidade.<br />
 <br />6.	Criação das Praças Educativas: oferecer atividades físicas, atividades formativas e atendimento Psico Socioemocional com psicólogos.<br />
<br />7.	Criação de uma rede articulada entre as Redes Estadual e Municipal de Ensino para oferecer atendimento de educação especial e garantir o acompanhamento efetivo desses alunos da creche até o Ensino Médio.<br />
<br />8.	Prorrogação automática dos contratos temporários dos professores com a criação de um sistema de pontuação para valorizar o tempo de serviço, computando como “prova de títulos” nos concursos públicos.<br />
<br />9.	Aprimoramento e execução do Plano de Carreira de todos os Servidores Municipais<br />
<br />10.	Aumentar o número de Escolas de Tempo Integral da Prefeitura para todos os bairros da cidade <br />
<br />11.	Revisão do limite de alunos por sala de aula: máximo de 13 alunos para o infantil e máximo de 20 alunos para o ensino fundamental.   <br />
<br />12.	Criação do Dia do “Rock Metal Bragantino” para valorizar os músicos da cidade e suas bandas, e oferecer uma cultura por meio de shows de bandas de rock e heavy metal direcionado a um público que tem sido esquecido pela Secretaria de Cultura local.<br />

     
    </>
}

export default function LeandroDialog({ data }) {
    var loc = useLocation().pathname
    var len = loc.length
    const [open, setOpen] = React.useState(
        loc.startsWith('/candidatos/')
        &&
        loc.substring(len - 5, len) == id)

    const handleClickOpen = () => {
        setOpen(true);
        window.history.pushState({}, undefined, '/candidatos/' + id);
    };

    var nav = useNavigate()
    const handleClose = () => {
        window.history.pushState({}, undefined, '/candidatos');
        setOpen(false);
    };
    return (
        <React.Fragment>
            <Button variant="outlined" onClick={handleClickOpen} style={{ padding: 0 }}>
                <Item><Typography >{name}</Typography>
                    <img src={img} style={{ width: '85%' }} />
                    <Typography >{id}</Typography>
                </Item>
            </Button>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open} >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {name} - {id}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    {content()}
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}