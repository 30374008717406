
import { InstagramEmbed } from 'react-social-media-embed';

import * as React from 'react';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useLocation, useNavigate } from "react-router-dom";

import img from "../res/Mauro despojado.png"
import video1 from "../res/Emprego.mp4"
import video2 from "../res/ePrefeitura.mp4"
import video3 from "../res/Educacao.mp4"
import video4 from "../res/Saude.mp4"
import video5 from "../res/Apresentacao.mp4"
const id = "18222"
const name = "Dr. Mauro Moreira"

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function content(){
    return <>
    <div style={{justifyContent: 'center', display: 'flex', width: '100%', height: '100%'}} width="100%">
                    
                    </div>
                    <InstagramEmbed
  url='https://www.instagram.com/mauro.moreira18/'
  clientAccessToken='123|456'
  Width={'100%'}
  height={"100%"}
  hideCaption={false}
  containerTagName='div'
  protocol=''
  injectScript
  onLoading={() => {}}
  onSuccess={() => {}}
  onAfterRender={() => {}}
  onFailure={() => {}}
/>
<Typography>
<h1>Compromisso e Coragem: Juntos pela Saúde, Educação, Emprego e Sustentabilidade!</h1>
</Typography>
                    <video id="samp" width="100%" height="500px" controls>
            { <source src={video5} type="video/mp4">
            </source> }
        </video>   <br />                   
        <br /> Sou Mauro Moreira, médico assim como meus irmãos, Alexandre e Nilso. Nessas eleições
estamos juntos por vocês. Sou candidato a vereador por vocês. Sinto enorme gratidão e
necessidade de contribuir com a nossa cidade. Sinto obrigação de devolver para vida um pouco
do que a vida me deu. Decidi ser candidato pelas pessoas e não por necessidade pessoal. Estou
indignado com os rumos da política e decidi reagir através da minha candidatura, por nós, o povo.
Como vereador, ajudarei a cuidar dos idosos, de nossas crianças e de toda nossa cidade. Vou
trabalhar na melhoria da Saúde, Educação, Meio Ambiente e Geração de Emprego & Renda.
Venha comigo melhorar a vida agora e garantir um futuro melhor para nossos filhos e netos.
Tenho ideias, compromisso, competência e coragem para representar você, cidadão<br />
                    <Typography>
                        <h1>Projetos</h1>
                    </Typography>
                    <Typography gutterBottom>
                        <h1>A Saúde Não Espera</h1>
                        <video id="samp" width="100%" height="500px" controls>
            { <source src={video4} type="video/mp4">
            </source> }
        </video>                        <br /> A espera na saúde gera sofrimento. Defendemos políticas públicas para acabar com a espera/sofrimento por exames, consultas, cirurgias, reabilitação e etc.
                        <br /> É possível diminuir a espera por exames, a espera consultas
com especialistas, a espera por cirurgias, a espera nas UPAS e Pronto Socorro. Propomos a
reserva de leitos hospitalares especialmente para nossos idosos. A espera na saúde gera
sofrimento. Venha comigo ajudar a melhorar a saúde no SUS.<br />
                    </Typography>
                    <br />
                    <Typography gutterBottom>
                        <h1>Educação Vale +</h1>
                        <video id="samp" width="100%" height="500px" controls>
            { <source src={video3} type="video/mp4">
            </source> }
        </video> <br />                        <br />A educação básica de Bragança precisa de socorro para garantir
o futuro de nossos filhos e netos. Nossos Professores são as pessoas mais importantes da
sociedade, vamos apoiá-los e valorizá-los com aumento salarial, melhorias no plano de
carreira e capacitação. Apenas 55% de nossas crianças têm vagas garantidas nas creches.
Lutaremos para que todas crianças de Bragança possuam vagas nas creches. A educação
infantil é muito importante para o desenvolvimento de nossas crianças, permitindo que os
pais trabalhem e gerem renda.<br />
                    </Typography>
                    <br />
                    <Typography>
                        <h1>Emprego & Renda</h1><br />
                        <video id="samp" width="100%" height="500px" controls>
            { <source src={video1} type="video/mp4">
            </source> }
        </video> <br />
        <br /> Incentivar a compra de produtos e serviços em
fornecedores e prestadores do município, fortalecendo a economia local e gerando emprego
em Bragança. Teremos como prioridade estabelecer unidades de capacitação profissional que
preparem nossos jovens para o mercado de trabalho.    <br />
                 </Typography>
                    <br />
                    <Typography>
                        <h1>ePrefeitura</h1><br />
                        <video id="samp" width="100%" height="500px" controls>
            { <source src={video2} type="video/mp4">
            </source> }
        </video> <br />
        <br />                         <br /> A modernização da prefeitura através do uso de tecnologias da
informação proverá maior agilidade, simplicidade e transparência durante o trâmite de
processos administrativos junto à prefeitura. Na maior parte dos casos, o munícipe será capaz
de submeter e acompanhar suas solicitações à prefeitura através de canais digitais.
                  </Typography>
                  <Typography gutterBottom>
                        <h1>Lixo Zero</h1><br />
                        Viabilização da reciclagem como atividade econômica para aumentar o reaproveitamento de resíduos e política de diminuição do impacto ambiental.
                    </Typography>
                    <br />
                    <Typography>
                        <h1>Vote Mauro Moreira - 18222</h1>
                    </Typography>
    </>
}

export default function MauroDialog({ data }) {
    var loc = useLocation().pathname
    var len = loc.length
    const [open, setOpen] = React.useState(
        loc.startsWith('/candidatos/')
        &&
        loc.substring(len - 5, len) == id)

    const handleClickOpen = () => {
        setOpen(true);
        window.history.pushState({}, undefined, '/candidatos/' + id);
    };

    var nav = useNavigate()
    const handleClose = () => {
        window.history.pushState({}, undefined, '/candidatos');
        setOpen(false);
    };
    return (
        <React.Fragment>
            <Button variant="outlined" onClick={handleClickOpen} style={{ padding: 0 }}>
                <Item><Typography >{name}</Typography>
                    <img src={img} style={{ width: '85%' }} />
                    <Typography >{id}</Typography>
                </Item>
            </Button>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open} >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {name} - {id}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    {content()}
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}