import * as React from 'react';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useLocation, useNavigate } from "react-router-dom";

import img from "../res/ValSantos.png"
// import video from "../res/mauro.mp4"
const id = "18777"
const name = "Val Santos"

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function content(){
    return <>
    <div style={{justifyContent: 'center', display: 'flex', width: '100%'}} width="100%">
                    <img src={img} style={{ width: '40%', }} />
                    </div>
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/TXj_fW_fXWY?si=IRrH6OlsVQBra5Ff" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    <Typography>
                        <h1>Prezados(as) eleitores(as),</h1>
                    </Typography>
                    <br />Meu nome é Maria Valdelice dos Santos, mais conhecida como Val. Nasci na cidade de
Martinópolis, em São Paulo, onde vivi até os 14 anos. Aos 19 anos, após completar os estudos,
iniciei minha trajetória no serviço público estadual, onde atuei por 34 anos até a minha
aposentadoria. Durante esse tempo, também me graduei em Serviço Social e trabalhei em
instituições de longa permanência para idosos, o que despertou em mim uma grande empatia pela
causa da terceira idade.<br />
<br />Ao longo da minha carreira, observei de perto as dificuldades enfrentadas pelos idosos,
principalmente no que diz respeito à convivência em instituições, ao atendimento na rede pública de
saúde — que infelizmente tem suas deficiências — e à carência afetiva que surge com o avanço da
idade. Essas experiências reforçaram meu compromisso com essa causa, e foram fundamentais para
a decisão de seguir nesse caminho de apoio e cuidado.<br />
<br />Em 2016, após me aposentar, mudei-me para Bragança Paulista. Infelizmente, em 2019, após uma
grande perda familiar, vivi um período de depressão. Foi nesse momento que me dediquei de forma
ainda mais intensa à profissão de cuidadora e acompanhante hospitalar de idosos, um trabalho que
já exercia com paixão e competência. Continuei nesse ramo até hoje, com o intuito de oferecer um
cuidado humanizado e digno aos nossos idosos, e também formei profissionais dessa área tão
essencial.<br />
<br />Agora, com esse conhecimento de vida e profissional, decidi me candidatar ao cargo de vereadora
em Bragança Paulista, Val Santos 18777. Minha bandeira é a defesa dos direitos dos idosos e a
promoção de políticas públicas em saúde, especialmente voltadas para esse público. Além disso,
quero lutar pela valorização e capacitação dos cuidadores e acompanhantes hospitalares, uma
profissão que precisa de mais atenção e suporte do poder público.<br />
<Typography>
     <h1>Projetos</h1>
     </Typography>
                    <Typography >
                     <b>Capacitação de cuidadores de idosos,</b><br />
                        Com cursos oferecidos pela prefeitura, para garantir
                        um atendimento mais qualificado e humanizado.                    </Typography>
                    <br />
                    <Typography >
                        <b>Atendimento psicológico na rede pública de saúde</b><br />
                        Para cuidadores familiares,
                        reconhecendo o desgaste emocional e a importância de cuidar de quem cuida.                    </Typography>
                    <br />
                    <Typography >
                        <b>Entrega de medicamentos de uso contínuo para idosos acima de 60 anos,</b><br />
                        Diretamente
                        nas residências, facilitando o acesso e promovendo mais conforto para quem já enfrenta                    </Typography>
                    <br />
        
                    <Typography>
                        <b>Elaboração de uma cartilha sobre os direitos e deveres dos cuidadores,</b><br />
                        Para garantir
                        uma contratação justa e evitar abusos.    <br />                
                        </Typography>
                        <br /> Com base nesses compromissos, venho pedir o seu apoio e o seu voto. Quero ser uma voz ativa na
Câmara Municipal, lutando por melhores condições de vida para os idosos e suas famílias. Conto
com você para juntos, fazermos a diferença na nossa < h1>Vote Val Santos 18777.</h1>
<iframe width="100%" height="100%" src="https://www.youtube.com/embed/lPKFaKIf3eY?si=oawxMUWeZfGn-k1C" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </>
}

export default function ValDialog({ data }) {
    var loc = useLocation().pathname
    var len = loc.length
    const [open, setOpen] = React.useState(
        loc.startsWith('/candidatos/')
        &&
        loc.substring(len - 5, len) == id)

    const handleClickOpen = () => {
        setOpen(true);
        window.history.pushState({}, undefined, '/candidatos/' + id);
    };

    var nav = useNavigate()
    const handleClose = () => {
        window.history.pushState({}, undefined, '/candidatos');
        setOpen(false);
    };
    return (
        <React.Fragment>
            <Button variant="outlined" onClick={handleClickOpen} style={{ padding: 0 }}>
                <Item><Typography >{name}</Typography>
                    <img src={img} style={{ width: '85%' }} />
                    <Typography >{id}</Typography>
                </Item>
            </Button>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open} >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {name} - {id}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    {content()}
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}