import * as React from 'react';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useLocation, useNavigate } from "react-router-dom";

import img from "../res/Alex.png"
// import video from "../res/mauro.mp4"
const id = "18144"
const name = "Alex"

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function content(){
    return <>
    <div style={{justifyContent: 'center', display: 'flex', width: '100%'}} width="100%">
                    <img src={img} style={{ width: '40%', }} />
                    </div>
                    <Typography>
                        <h1>Com uma trajetória de muita pela dedicação e trabalho duro, trago minha experiência para fazer a diferença na política de Bragança Paulista</h1>
                    </Typography>
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/xh2JkjOlgAw?si=MX5s1SLy6kSVeh5Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe><br />
                    <Typography>
                    <br /> Minha trajetória de vida sempre foi marcada pela dedicação e pelo trabalho duro. Durante muitos
anos, trabalhei com meus irmãos na área de pintura, atuei também em uma fábrica de chocolates e
nas entregas dessa empresa. Além disso, sou empreendedor em nossa cidade, abrindo duas lojas de
salgados e, mais tarde, uma pequena loja de motos.
<br />Essas experiências me ensinaram que, com determinação e esforço, todos nós podemos alcançar
nossos objetivos. Hoje, trago esse espírito empreendedor e comprometido para a política, com o
objetivo de inspirar e ajudar todo o povo de Bragança Paulista.
<br />É com grande orgulho que me apresento a vocês como candidato a vereador em nossa amada cidade,
Bragança Paulista. Meu nome <b>Alex Campos número 18144</b>, candidato pelo partido REDE (18), e
estou pronto para trabalhar incansavelmente para promover a segurança e o bem-estar de toda a
população.
<br />
                        Acredito que juntos podemos construir uma cidade mais justa, segura e inclusiva para todos. Meu
compromisso é trabalhar com transparência, ouvindo as necessidades da população e buscando
soluções que beneficiem a todos.
Conto com seu apoio e confiança para juntos, fazermos de Bragança Paulista um lugar ainda melhor
para viver.
<br />Ao longo da minha caminhada, desenvolvi propostas focadas em segurança pública e privada, que
são prioridades para mim. Entre minhas principais propostas estão:
                    </Typography>
                    <Typography>
                        <h1>Projetos</h1>
                    </Typography>
                    <Typography gutterBottom>
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/btW3mCjAzWI?si=EJ7agRbH5w24w2XV" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        <b>Melhorias na infraestrutura de segurança </b><br />
                        Garantir patrulhamento ostensivo,
especialmente nas periferias, com monitoramento eletrônico nas áreas de alto risco.
                    </Typography>
                    <br />
                    <Typography gutterBottom>
                        <b>Apoio à saúde e educação de qualidade</b><br />
                        Facilitar o acesso da população a serviços públicos
                        essenciais, garantindo mais oportunidades de emprego e desenvolvimento urbano.                    </Typography>
                    <br />
                    <Typography gutterBottom>
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/MRb0Z1Ib6zw?si=ArwBo3okuMEhhfu0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

                        <b>Segurança nas escolas e bairros:</b><br />
                        Criar projetos de segurança que protejam nossas crianças
                        e as comunidades nas regiões mais vulneráveis.                
                    </Typography>
                    <br />
                    <Typography>
                        <b>Gestão e ordenação do trânsito:</b><br />
                        Implementar melhores condições de estacionamento e
                        circulação nas áreas de comércio, além de aumentar a segurança no trânsito em toda a cidade.                    </Typography>
                    <br />
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/4eRhtArR4cw?si=ArxNVRgtAz2IAcQJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

                    <Typography>
                       Convidamos você a acessar os Links abaixo. Eles Levarão você diretamente às nossas
redes sociais                    <b>Alex Campos 18144</b> </Typography>
<iframe width="100%" height="100%" src="https://www.youtube.com/embed/p81xVC8XUX0?si=LjlNzU8iGSpDntiH" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

    </>
}

export default function AlexDialog() {
    var loc = useLocation().pathname
    var len = loc.length
    const [open, setOpen] = React.useState(
        loc.startsWith('/candidatos/')
        &&
        loc.substring(len - 5, len) == id)

    const handleClickOpen = () => {
        setOpen(true);
        window.history.pushState({}, undefined, '/candidatos/' + id);
    };

    var nav = useNavigate()
    const handleClose = () => {
        window.history.pushState({}, undefined, '/candidatos');
        setOpen(false);
    };
    return (
        <React.Fragment>
            <Button variant="outlined" onClick={handleClickOpen} style={{ padding: 0 }}>
                <Item><Typography >{name}</Typography>
                    <img src={img} style={{ width: '85%' }} />
                    <Typography >{id}</Typography>
                </Item>
            </Button>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open} >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {name} - {id}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    {content()}
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}