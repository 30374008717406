import * as React from 'react';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useLocation, useNavigate } from "react-router-dom";

import img from "../res/VanessaBenchik.png"
// import video from "../res/mauro.mp4"
const id = "18999"
const name = "Vanessa Benchik"

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function content() {
    return <>
        <div style={{ justifyContent: 'center', display: 'flex', width: '100%' }} width="100%">
            <img src={img} style={{ width: '40%', }} />
        </div>
        <br />   <iframe width="100%" height="100%" src="https://www.youtube.com/embed/XOf3ShOD2wM?si=MMGt2RolsEGW1C1S" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <Typography>
            <h1>O Que Nos Motiva</h1>
        </Typography>
        Me chamo Vanessa Benchik, sou mãe, advogada, administradora de empresas e agora um
nome a sua disposição como candidata a vereadora em Bragança Paulista.
Sempre participei indiretamente da política, afinal as decisões tomadas nesse ambiente
refletem diretamente em nossos dias, principalmente quando fazemos uso dos sistemas
públicos.<br />
<br />Justamente por isso refleti e notei a necessidade de participar diretamente desse cenário, o
que fez com que tivesse uma percepção ainda maior das dificuldades que enfrentamos.
Para isso busquei capacitação e tive o privilégio de me tornar uma liderança RenovaBR,
estudando em uma das maiores escolas de formação política do país.<br />
<br />Minha trajetória é marcada por desafios, de quem concluiu os estudos depois da idade
escolar, com filhos, casada e sem muitos recursos. Mas sempre, com muita dedicação,
trabalho e compromisso em tudo que faço.<br />
<br />Nesse projeto político, uma das minhas pautas é a valorização das mulheres e o
empreendedorismo feminino, com foco na capacitação e geração de renda. Defendo a
transparência na gestão pública e a importância da participação popular nas decisões que
impactam nossas vidas.<br />
<br />Quero com sua participação, pensar e construir mais oportunidades para que a voz de
todos seja ouvida, especialmente de quem está na base e precisa de respostas rápidas.
Sei que juntos podemos fazer a diferença e construir esse cenário para Bragança Paulista
mais inclusiva e participativa, e ainda, entender as nossas deficiências, aprender e crescer
a partir disso.<br /><br />
<br /><iframe width="100%" height="100%" src="https://www.youtube.com/embed/AXNVjQ2IGDY?si=G2zdnN1JtmjEzl3I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
<br />
    <Typography>
     <h1>Projetos</h1>
     </Typography>
 <Typography gutterBottom>
        <b>Empreendedorismo Feminino e Capacitação:</b><br /> 
   Programas para capacitar mulheres, promovendo incentivos para educação,  fortalecer o empreendedorismo local para  autonomia financeira, inclusão social e combate à violência. 
        </Typography>
        <br />
        <Typography gutterBottom>
            <b>Transparência na Gestão Pública:</b><br />
            Compromisso com uma gestão transparente, utilizando  plataformas e ferramentas para divulgar ações e abrir canais de comunicação.        </Typography>
        <br />
        <Typography gutterBottom>
            <b>Participação Popular nos Espaços de Decisão:</b><br />
            Viabilização da reciclagem como atividade econômica para aumentar o reaproveitamento de resíduos e política de diminuição do impacto ambiental.
         </Typography>    
         <Typography>
     <h1>Vote Vanessa Benchick 18999</h1>
     </Typography> 
    </>
}

export default function VanessaDialog({ data }) {
    var loc = useLocation().pathname
    var len = loc.length
    const [open, setOpen] = React.useState(
        loc.startsWith('/candidatos/')
        &&
        loc.substring(len - 5, len) == id)

    const handleClickOpen = () => {
        setOpen(true);
        window.history.pushState({}, undefined, '/candidatos/' + id);
    };

    var nav = useNavigate()
    const handleClose = () => {
        window.history.pushState({}, undefined, '/candidatos');
        setOpen(false);
    };
    return (
        <React.Fragment>
            <Button variant="outlined" onClick={handleClickOpen} style={{ padding: 0 }}>
                <Item><Typography >{name}</Typography>
                    <img src={img} style={{ width: '85%' }} />
                    <Typography >{id}</Typography>
                </Item>
            </Button>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open} >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {name} - {id}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    {content()}
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}